import React from "react"




const Dashboard =()=>{
    return (

        <div className="d-flex jusitfy-content-center flex-column align-items-center " style={{height:"inherit", width:"inherit"}}>
           {/* <img className="mx-auto " src={require("../../assets/images/dashboard/logo.png")} style={{width:200,height:250, marginTop:100}}/>  */}
        </div>
    )
}

export default Dashboard;