import React, { Component } from "react";
import Fade from "@material-ui/core/Fade";
//import RespNavBar from "./responsiveNavBar";

class MailBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <h2>Besmelleh - MailBox</h2>;
  }
}

export default MailBox;
