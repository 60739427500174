import React, { Component } from "react";
import Fade from "@material-ui/core/Fade";
import DefaultLayout from "../components/dashboard/defaultLayout";

 const HomePage =()=>{
  
    return <DefaultLayout />;
  
}

export default HomePage;
