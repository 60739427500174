import React, { Component } from "react";
import Fade from "@material-ui/core/Fade";
//import RespNavBar from "./responsiveNavBar";

class Notifications  extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <h2>Notifications (under construction) </h2>;
  }
}

export default Notifications;
